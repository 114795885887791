<template>
    <div :style="{height:availHeight+'px'}">
        <video id="video" :width="availWidth" :height="availHeight" controls ref="video"></video>
        <!-- <video id="video" :width="availWidth" :height="availHeight" autoplay muted playsinline="true" webkit-playsinline="true" controls ref="video"></video> -->
    </div>
</template>

<script>
let Hls = require('hls.js');
export default {
    data(){
        return{
            projectCode:'',
            availHeight:'',
            availWidth:'',
            // isAndroid:true
        }
    },
    mounted(){
    
        this.projectCode = window.sessionStorage.getItem('projectCode');
        
        document.getElementById('video').style.height = document.documentElement.clientHeight+'px';
        document.getElementById('video').style.width = document.documentElement.clientWidth+'px';
        // this.$api.SLOPE.getHls({
        //     bridgeCode:this.bridgeCode
        // }).then( d =>{
        //     this.playUrl = d.playUrl;
        //     // this.playUrl = 'http://rtmp.ahdcloud.com/hls/170820200900084/index.m3u8'; //测试地址
        //     this.getStream(this.playUrl);
        // })
        this.$api.CAMERA.getAllDev({
            projectCode:this.projectCode,
            devType:"DH"
        }).then(d => {
            console.log(d);
            if(d.length > 0){
                this.playUrl = d[0].comment;
                this.getStream(this.playUrl);
                this.$toast('实时视频接入中，请稍等...');
            }else{
                this.$toast('暂无实时视频接入，请稍后重试');
            }
            
        })
        
        // var u = navigator.userAgent, app = navigator.appVersion;
        // var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g    
        // var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端    
        // if (isAndroid) {
        //     this.isAndroid = true;
        // }    
        // if (isIOS) {
        //     this.isAndroid = false;
        // }
    },
    methods:{
        // 获取hls视频流
        getStream(source) {
            if (Hls.isSupported()) {
                this.hls = new Hls();
                this.hls.loadSource(source);
                this.hls.attachMedia(this.$refs.video);

                this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    console.log("加载成功");
                    this.$refs.video.play();
                });
            }else if (this.$refs.video.canPlayType('application/vnd.apple.mpegurl')) {
                // 以下逻辑兼容IOS浏览器内核
                this.$refs.video.src = source;
                this.$refs.video.addEventListener('loadedmetadata',function() {
                    this.$refs.video.play();
                });
            }
        },
    },
    destroyed(){
        if(this.hls){
            this.hls.destroy();
        }
        
    }
}
</script>

<style scoped>
.box-video{
    overflow: hidden;
}
</style>